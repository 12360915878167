function performSearch(queryInput) {
    const loaderState = (state) => toggleLoader(state);
    const toggleNoResults = (show) => {
        document.querySelectorAll('.no-results').forEach((element) => {
            element.classList.toggle('tw-block', show);
            element.classList.toggle('tw-hidden', !show);
        });
    };

    const toggleErrorMsg = (show) => {
        document.querySelectorAll('.error-msg').forEach((element) => {
            element.classList.toggle('tw-block', show);
            element.classList.toggle('tw-hidden', !show);
        });
    };

    toggleNoResults(false);
    toggleErrorMsg(false);
    loaderState(true);

    const currentQueryInput = document.getElementById('query-input-map');
    let gaEventName;

    gaEventName = currentQueryInput ? 'servicesPageSearchSubmit' : 'homePageSearchSubmit';

    if (!queryInput) {
        sessionStorage.getItem('queryInput') && sessionStorage.removeItem('queryInput');
        trackGoogleAnalyticsEvent(gaEventName, {});
        window.location.href = window.location.origin + '/' + window.lang + '/croatia';
        return;
    }

    const apiUrl = `/api/ai/search?input=${encodeURIComponent(queryInput)}`;

    fetch(apiUrl, { headers: { Accept: 'application/json' } })
        .then((response) => response.json())
        .then((data) => {
            if (data.url) {
                trackGoogleAnalyticsEvent(gaEventName, data.filters);
                window.location.href = window.location.origin + '/' + window.lang + data.url;
                sessionStorage.setItem('queryInput', queryInput);
            } else {
                toggleNoResults(true);
            }
        })
        .catch((error) => {
            console.error('Error performing search:', error);
            toggleErrorMsg(true);
        })
        .finally(() => loaderState(false));
}

function toggleLoader(show) {
    const loaders = document.querySelectorAll('.blade-loader');
    const results = document.querySelectorAll('.results');

    loaders.forEach((loader) => {
        if (show) {
            loader.classList.remove('tw-hidden');
            loader.classList.add('tw-block');
        } else {
            loader.classList.add('tw-hidden');
            loader.classList.remove('tw-block');
        }
    });

    results.forEach((result) => {
        if (show) {
            result.classList.add('tw-hidden');
            result.classList.remove('tw-block');
        } else {
            result.classList.remove('tw-hidden');
            result.classList.add('tw-block');
        }
    });
}

(function () {
    const infoModal = document.getElementById('info-modal');
    const body = document.getElementsByTagName('body')[0];
    if (infoModal) {
        const openInfoModals = document.querySelectorAll('.open-info-modal');

        openInfoModals.forEach((openInfoModal) => {
            openInfoModal.addEventListener('click', function (e) {
                e.preventDefault();
                if (infoModal.classList.contains('tw-flex')) {
                    infoModal.classList.remove('tw-flex');
                    body.classList.remove('prevent-scroll');
                    infoModal.classList.add('tw-hidden');
                } else {
                    infoModal.classList.add('tw-flex');
                    body.classList.add('prevent-scroll');
                    infoModal.classList.remove('tw-hidden');
                }

                const closeBtn = infoModal.querySelector('.close');
                closeBtn.addEventListener('click', function (e) {
                    e.preventDefault();
                    infoModal.classList.remove('tw-flex');
                    body.classList.remove('prevent-scroll');
                    infoModal.classList.add('tw-hidden');
                });
            });
        });
    }
})();

//Populate query-input-map input with the query from the homescreen
(function () {
    const queryInputMap = document.getElementById('query-input-map');

    if (queryInputMap) {
        const storedQuery = sessionStorage.getItem('queryInput');

        // If there's a stored value, populate the query-input-map field
        if (storedQuery) queryInputMap.value = storedQuery;
    }
})();

// Button for clearing the search field
(function () {
    function setupReset(inputId, buttonId) {
        const queryInput = document.getElementById(inputId);
        const resetButton = document.getElementById(buttonId);

        // Only proceed if elements are present
        if (!queryInput || !resetButton) return;

        let isVisible = false;

        function toggleResetButton() {
            const hasValue = queryInput.value.trim() !== '';

            if (hasValue !== isVisible) {
                resetButton.classList.toggle('tw-invisible', !hasValue);
                resetButton.classList.toggle('tw-opacity-0', !hasValue);
                resetButton.classList.toggle('tw-visible', hasValue);
                resetButton.classList.toggle('tw-opacity-1', hasValue);
                // Cache visibility state
                isVisible = hasValue;
            }
        }

        function resetInput() {
            queryInput.value = '';
            sessionStorage.removeItem('queryInput');
        }

        function handleReset() {
            resetInput();
            toggleResetButton();
        }

        resetButton.addEventListener('click', handleReset);
        queryInput.addEventListener('input', toggleResetButton);
        document.addEventListener('DOMContentLoaded', toggleResetButton);
    }
    // Set up reset functionality for different input/button pairs
    setupReset('query-input-map', 'reset-button-map');
    setupReset('query-input-fullscreen', 'reset-button-fullscreen');
})();

//Search Suggestions
(function () {
    document.addEventListener('DOMContentLoaded', function () {
        const suggestionButtons = document.querySelectorAll('.suggestion-btn');
        suggestionButtons.forEach((btn) => {
            const suggestionText = btn.textContent.trim();

            btn.addEventListener('click', function () {
                performSearch(suggestionText);
            });
        });
    });
})();

//Search
(function () {
    function addSearchEventListener(buttonId, inputId) {
        const searchButton = document.getElementById(buttonId);
        const queryInput = document.getElementById(inputId);

        if (searchButton) {
            searchButton.addEventListener('click', function () {
                const query = queryInput.value.trim();
                performSearch(query);
            });
        }

        if (queryInput) {
            queryInput.addEventListener('keypress', function (event) {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    const query = queryInput.value.trim();
                    performSearch(query);
                }
            });
        }
    }

    // Attach the event listeners
    addSearchEventListener('search-button-map', 'query-input-map');
    addSearchEventListener('search-button-fullscreen', 'query-input-fullscreen');
    addSearchEventListener('search-button-fullscreen-off', 'search-input-trigger');
})();

//Toggle mobile searchbox
(function () {
    const elements = {
        fullscreenSearchInputWrapper: document.getElementById('query-input-wrapper-fullscreen'),
        searchInputTrigger: document.getElementById('search-input-trigger'),
        mobileSearchContainer: document.getElementById('mobile-search-container'),
        fullscreenSearchInput: document.getElementById('query-input-fullscreen'),
        closeFullscreenButton: document.getElementById('close-fullscreen-btn'),
    };

    const body = document.getElementsByTagName('body')[0];

    function updateWrapperBorder(addDarkGreen) {
        elements.fullscreenSearchInputWrapper.classList.toggle('tw-border-dark-green', addDarkGreen);
        elements.fullscreenSearchInputWrapper.classList.toggle('tw-border-light-grey2', !addDarkGreen);
    }

    function toggleFullscreen(isFullscreen) {
        elements.mobileSearchContainer.classList.toggle('fullscreen', isFullscreen);
        isFullscreen ? elements.fullscreenSearchInput.focus() : elements.fullscreenSearchInput.blur();
        updateWrapperBorder(isFullscreen);
        body.classList.toggle('prevent-scroll');
    }

    if (elements.fullscreenSearchInput) {
        elements.fullscreenSearchInput.addEventListener('focus', () => updateWrapperBorder(true));
        elements.fullscreenSearchInput.addEventListener('blur', () => updateWrapperBorder(false));
    }

    if (elements.searchInputTrigger)
        elements.searchInputTrigger.addEventListener('click', () => toggleFullscreen(true));

    if (elements.closeFullscreenButton)
        elements.closeFullscreenButton.addEventListener('click', () => toggleFullscreen(false));
})();

function trackGoogleAnalyticsEvent(gaEventName, data) {
    const gaEventData = {
        event: gaEventName || '',
        experienceType: data['category-name'] || '',
        location: data['destination-name'] || '',
        numberOfPeople: data.persons || null,
        priceMin: data['price-from'] || null,
        priceMax: data['price-to'] || null,
        onlyPrivate: data['only-private-group'] || null,
        onlyGuided: data['only-guided'] || null,
        onlyMixed: data['only-mixed-group'] || null,
        tags: data.tags || [],
    };
    dataLayer.push(gaEventData);
}
